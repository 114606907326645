// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  FsHeading,
  FsParagraph,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import Image from 'next/image';
import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer';
import * as S from './ContentRenderer.styles';

interface ContentRendererProps {
  content?: BlocksContent[];
}

export const ContentRenderer = ({ content }: ContentRendererProps) => {
  if (content === undefined || content === null || content?.length === 0) {
    return;
  }

  return (
    <S.Section>
      <BlocksRenderer
        content={content}
        blocks={{
          paragraph: ({ children }) => <FsParagraph>{children}</FsParagraph>,
          code: ({ children }) => <pre className="code-block">{children}</pre>,
          heading: ({ children, level }) => {
            switch (level) {
              case 1:
                return (
                  <FsHeading tag={HeadingTag.H1} size={HeadingSize['XL']}>
                    {children}
                  </FsHeading>
                );
              case 2:
                return (
                  <FsHeading tag={HeadingTag.H2} size={HeadingSize['LG']}>
                    {children}
                  </FsHeading>
                );
              case 3:
                return (
                  <FsHeading tag={HeadingTag.H3} size={HeadingSize['MD']}>
                    {children}
                  </FsHeading>
                );
              case 4:
                return (
                  <FsHeading tag={HeadingTag.H4} size={HeadingSize['SM']}>
                    {children}
                  </FsHeading>
                );
              case 5:
                return (
                  <FsHeading tag={HeadingTag.H5} size={HeadingSize['XS']}>
                    {children}
                  </FsHeading>
                );
              case 6:
                return (
                  <FsHeading tag={HeadingTag.H6} size={HeadingSize['2XS']}>
                    {children}
                  </FsHeading>
                );
              default:
                return (
                  <FsHeading tag={HeadingTag.H1} size={HeadingSize['XL']}>
                    {children}
                  </FsHeading>
                );
            }
          },
          link: ({ children, url }) => (
            <a className="anchor" href={url} target="_blank">
              {children}
            </a>
          ),
          quote: ({ children }) => (
            <blockquote className="blockquote">{children}</blockquote>
          ),
          image: ({ image }) => (
            <div className="image">
              <Image
                src={image.url || image.url}
                fill
                alt={image.alternativeText || ''}
              />
            </div>
          ),
        }}
        modifiers={{
          code: ({ children }) => (
            <code className="inline-code">{children}</code>
          ),
        }}
      />
    </S.Section>
  );
};
