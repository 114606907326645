import { media } from '@mf/common/utils/styles/index';
import { FsImage } from '@printi/ds-offset-react-core';
import styled, { css } from 'styled-components';

interface ContainerProps {
  selected?: boolean;
}

export const Image = styled(FsImage)`
  ${media.lessThan('desktop')} {
    aspect-ratio: unset;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2x);
  flex: 1 0 0;

  &:hover {
    cursor: pointer;
  }

  img {
    max-height: 214px;
    border: 3px solid transparent;
    box-sizing: border-box;

    ${({ selected }) =>
      selected &&
      css`
        border: 3px solid var(--action-border-primary-color);
      `}

    ${media.lessThan('mobile')} {
      max-height: 185px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1x);
  align-self: stretch;
`;

export const HeadingDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-half);
  flex: 1 0 0;
  align-self: stretch;
`;
