import { FsModal } from '@printi/ds-offset-react-core';
import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';

export const StyledReviewGallery = styled.div`
  display: grid;
  gap: var(--spacing-4x);
  grid-template-columns: 1.25fr 1fr;
  grid-auto-flow: column;

  ${media.lessThan('touch')} {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
  }

  .modal-content {
    padding-bottom: var(--spacing-5x);
    overflow: auto;
    > :first-child {
      > :first-child {
        height: 0;
      }
    }
    > div:nth-child(2) {
      align-items: flex-start;
      position: relative;

      > div:first-child {
        span:first-child {
          color: var(--color-grey-900);
          font-family: var(--font-family-brand);
          font-size: var(--font-size-xs);
          font-weight: var(--font-weight-medium);
          line-height: var(--line-height-md);
        }

        span:last-child {
          color: var(--color-grey-700);
          font-family: var(--font-family-base);
          font-size: var(--font-size-xs);
          font-weight: var(--font-weight-regular);
          line-height: var(--line-height-lg);
        }
      }
      > div:last-child {
        span {
          color: var(--color-grey-500);
          font-family: var(--font-family-base);
          font-size: var(--font-size-2xs);
          font-weight: var(--font-weight-regular);
          line-height: var(--line-height-lg);
          top: -35px;
          position: absolute;
        }
      }
    }

    .printi-answer {
      padding-top: 0;
      margin-top: var(--spacing-2_5x);
      > ::before {
        content: '';
        content: '';
        min-width: 24px;
        min-height: 18px;
        display: flex;
        margin: -7px 0 0 var(--spacing-5x);
        position: relative;
        border-left: 2px solid #f0f0f0;
        border-bottom: 2px solid #f0f0f0;
      }
      > div:first-child {
        align-items: flex-start;
        span:first-child {
          color: var(--color-grey-900);
          font-family: var(--font-family-brand);
          font-size: var(--font-size-xs);
          font-weight: var(--font-weight-medium);
          line-height: var(--line-height-md);
        }

        span:last-child {
          color: var(--color-grey-700);
          font-family: var(--font-family-base);
          font-size: var(--font-size-xs);
          font-weight: var(--font-weight-regular);
          line-height: var(--line-height-lg);
        }
      }
    }
  }
`;

export const StyledModal = styled(FsModal)`
  top: 40px;
  z-index: 9999;
  #modal {
    height: 80vh;
    width: 80vw;
    max-width: 1200px;
    max-height: 800px;

    ${media.lessThan('touch')} {
      height: 100vh;
      max-height: inherit;
      width: 100vw;
      top: 0;
    }
  }
`;

export const StyledGalleryProduct = styled.div`
  min-width: 55%;
  max-height: 450px;

  ${media.lessThan('touch')} {
    max-height: inherit;
  }

  .solo-photo {
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }

  > div {
    ${media.lessThan('touch')} {
      overflow: hidden;
    }
    > :last-child {
      height: calc(100px + var(--border-width-md));
      gap: var(--spacing-1x);
      overflow: auto;
      width: auto;

      ${media.lessThan('touch')} {
        display: flex;
      }

      > a {
        flex: 1;
        width: fit-content;
        height: fit-content;
        max-height: calc(100px - var(--border-width-md));
        min-width: 100px;
        max-width: 100px;
      }

      img {
        height: calc(100px - var(--border-width-md));
      }
    }
    > :first-child {
      max-height: 450px;
      > :first-child {
        max-height: 450px;
      }
    }
  }
`;
