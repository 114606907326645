import styled from 'styled-components';

export const TrustvoxEmptyContainer = styled.div`
  max-width: 550px;
  text-align: center;
  margin: var(--spacing-10x) auto var(--spacing-15x);

  h3 {
    margin-bottom: var(--spacing-2x);
  }

  h3::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='120' viewBox='0 0 200 120' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M200 0.0839844H33.8086V49.9414H176.373V59.6588L185.119 49.9414H200V0.0839844Z' fill='%23F5F5F5'/%3E%3Cpath d='M58.7435 13.9346L61.231 21.5903H69.2807L62.7683 26.3218L65.2558 33.9775L58.7435 29.246L52.2312 33.9775L54.7187 26.3218L48.2063 21.5903H56.256L58.7435 13.9346Z' fill='%23191919'/%3E%3Cpath d='M87.8255 13.9346L90.313 21.5903H98.3627L91.8504 26.3218L94.3379 33.9775L87.8255 29.246L81.3132 33.9775L83.8007 26.3218L77.2884 21.5903H85.338L87.8255 13.9346Z' fill='%23191919'/%3E%3Cpath d='M116.908 13.9346L119.395 21.5903H127.445L120.932 26.3218L123.42 33.9775L116.908 29.246L110.395 33.9775L112.883 26.3218L106.37 21.5903H114.42L116.908 13.9346Z' fill='%23191919'/%3E%3Cpath d='M145.99 13.9346L148.477 21.5903H156.527L150.014 26.3218L152.502 33.9775L145.99 29.246L139.477 33.9775L141.965 26.3218L135.452 21.5903H143.502L145.99 13.9346Z' fill='%23191919'/%3E%3Cpath d='M175.072 13.9346L177.559 21.5903H185.609L179.096 26.3218L181.584 33.9775L175.072 29.246L168.559 33.9775L171.047 26.3218L164.534 21.5903H172.584L175.072 13.9346Z' fill='%23191919'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M166.191 60.1719H0V110.029H14.5813L23.3268 119.747V110.029H166.191V60.1719Z' fill='%23F5F5F5'/%3E%3Crect x='13.8398' y='74.0225' width='108.371' height='2.76986' rx='1.38493' fill='%23D9D9D9'/%3E%3Crect x='13.8398' y='83.7197' width='138.493' height='2.76986' rx='1.38493' fill='%23D9D9D9'/%3E%3Crect x='13.8398' y='93.4141' width='100.061' height='2.76986' rx='1.38493' fill='%23D9D9D9'/%3E%3C/svg%3E");
    display: block;
    margin-bottom: 32px;
  }

  .ts-v-footer-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-4x);
    color: var(--color-grey-500);
    font-family: var(--font-family-base);
    font-size: var(--font-size-2xs);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-lg);
  }
`;

export const TrustvoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 80px;
  margin: var(--spacing-10x) unset var(--spacing-15x);

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;
  }

  .loadMore {
    margin: var(--spacing-5x) auto 0;
  }

  #modal-container .no-content {
    display: none;
  }
`;

export const RateContainer = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .sticky {
    position: sticky;
    top: 100px;

    > :nth-child(2) {
      gap: 0;
      height: 30px;
    }
  }

  .ts-v-footer-container {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--color-grey-500);
    font-family: var(--font-family-base);
    font-size: var(--font-size-2xs);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-lg);
  }
`;

export const Review = styled.div`
  border-bottom: 1px solid var(--elements-border-color);
  padding-bottom: var(--spacing-5x);
  > div {
    gap: 0;
  }
  > div:nth-child(2) {
    align-items: flex-start;

    > div:first-child {
      span:first-child {
        color: var(--color-grey-900);
        font-family: var(--font-family-brand);
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-height-md);
      }

      span:last-child {
        color: var(--color-grey-700);
        font-family: var(--font-family-base);
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-lg);
      }
    }
    > div:last-child {
      span {
        color: var(--color-grey-500);
        font-family: var(--font-family-base);
        font-size: var(--font-size-2xs);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-lg);
      }
    }
  }

  .printi-answer {
    padding-top: 0;
    margin-top: var(--spacing-2_5x);
    > ::before {
      content: '';
      content: '';
      min-width: 24px;
      min-height: 18px;
      display: flex;
      margin: -7px 0 0 var(--spacing-5x);
      position: relative;
      border-left: 2px solid var(--color-grey-100);
      border-bottom: 2px solid var(--color-grey-100);
    }
    > div:first-child {
      align-items: flex-start;
      span:first-child {
        color: var(--color-grey-900);
        font-family: var(--font-family-brand);
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-height-md);
      }

      span:last-child {
        color: var(--color-grey-700);
        font-family: var(--font-family-base);
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-lg);
      }
    }
  }

  .image-list {
    display: flex;
    gap: var(--spacing-2x);

    > * {
      flex-grow: 0;
      width: auto;
      width: 80px;
      margin-right: var(--spacing-half);
      cursor: pointer;
    }
  }

  .more-images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: var(--color-grey-50);
    cursor: pointer;
  }
`;
