'use client';

import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';
import { FsBreadcrumb, FsPopover } from '@printi/ds-offset-react-core';

export const TextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const StyledBreadcrumb = styled(FsBreadcrumb)`
  margin: var(--spacing-2x) 0 !important;
`;

export const StyledFsContainer = styled.div`
  padding: 0 var(--spacing-5x);
  min-height: 500px;

  .first-column {
    > div:first-child {
      max-width: 556px !important;
      margin-bottom: var(--spacing-8x);

      > div:first-child {
        height: unset;
      }
    }
  }

  ${media.lessThan('mobile')} {
    padding: 0 var(--spacing-2x);
  }

  @media (max-width: 1025px) {
    > .fs-row {
      display: grid;
      margin: 0;
      width: 100%;
      grid-column-gap: var(--grid-xs-gutter);
      column-gap: var(--grid-xs-gutter);
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
`;

export const StickyComponent = styled.div`
  position: sticky;
  top: 100px;

  ${media.lessThan('mobile')} {
    position: static;
  }
`;

export const StyledContainer = styled.div`
  padding: var(--spacing-5x) var(--spacing-4x);
  border-radius: var(--default-border-radius);
  border: var(--default-border-width) solid var(--elements-border-color);
  margin-bottom: var(--spacing-2x);

  > .input-select {
    margin-bottom: var(--spacing-4x);
  }

  .quantity-label {
    font: var(--_label-md-2-font);
    letter-spacing: var(--_label-md-2-letter-spacing);
    color: var(--text-label-color);
    margin-bottom: var(--spacing-1x);
  }

  .quantity-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1_5x);
    margin-bottom: var(--spacing-4x);
    > .input-select {
      gap: 0;
    }

    p {
      margin-top: var(--spacing-1_5x);
    }
  }

  .fullButtons {
    width: 100%;
  }
  .fullButtons:first-of-type {
    margin-bottom: var(--spacing-2x);
  }

  @media screen and (max-width: 1025px) {
    border: none;
    padding: inherit;
    padding-top: var(--spacing-4x);
    padding-bottom: var(--spacing-4x);

    > div.cing {
      padding: 0 !important;
    }
  }
`;

export const InfoContainer = styled(StyledContainer)`
  display: grid;
  padding: 0;
  border: 0;
  grid-template-columns: repeat(4, minmax(200px, auto));

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(288px, 1fr));
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const StyledSection = styled.div`
  display: grid;
  margin: var(--spacing-10x) 0 !important;

  @media screen and (max-width: 1025px) {
    margin-top: var(--spacing-6x) !important;
    margin-bottom: var(--spacing-6x) !important;
  }

  > .first-column {
    @media screen and (min-width: 1024px) {
      margin-right: var(--spacing-15x) !important;
    }
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2x);
  margin-bottom: var(--spacing-4x);
`;

export const DesktopView = styled.div`
  @media (max-width: 1025px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 1026px) {
    display: none;
  }
`;

export const StyledFixedBar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['visible'].includes(prop),
})<{ visible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: -81px;
  transform: translateY(-100%);
  z-index: var(--z-index-fixed);
  transition: 0.5s linear bottom;

  ${(props) => {
    if (props.visible) {
      return `
        bottom: 0;
        transform: translateY(0%);
      `;
    }
    if (!props.visible) {
      return `
        bottom: -80px;
        transform: translateY(0%);
    `;
    }
  }}
`;

export const ToastButtonArea = styled.div`
  width: 100%;

  > div {
    width: auto;
  }
`;

export const StyledPopover = styled(FsPopover)`
  bottom: var(--spacing-3x);
  box-sizing: border-box;
  gap: var(--spacing-2x);
  max-width: 445px;
  position: fixed;
  right: var(--spacing-5x);
  width: 100%;
  z-index: var(--z-index-level-4);
`;

export const StyledPopoverHeader = styled.div`
  display: flex;
  gap: var(--spacing-1x);
  justify-content: space-between;
`;

export const StyledPopoverContent = styled.div`
  position: relative;
  width: 100%;
  height: 197px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 7fr 4fr;
  padding: var(--spacing-none) var(--spacing-none) var(--spacing-10x)
    var(--spacing-none);
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing-8x);
  align-self: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: auto;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2x);

  position: sticky;
  top: 80px;

  @media (max-width: 1024px) {
    width: 100%;
    position: static;
    top: auto;
    flex-direction: row;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none) var(--spacing-5x) var(--spacing-4x)
    var(--spacing-5x);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4x);
  align-self: stretch;

  @media (max-width: 1024px) {
    display: flex;
    padding: var(--spacing-2x);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-4x);
    align-self: stretch;
  }
`;

export const StyledGalleryProduct = styled.div`
  > div:first-child {
    flex-direction: row !important;
    > div:first-child {
      flex-direction: column !important;
    }
  }
`;
