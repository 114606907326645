import { api } from '@mf/common/config/axios/serviceApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { TAxiosDefaultConfig } from 'repositories/defaults/types';
import {
  TDefaultCombinationsQuantities,
  TFreightOption,
  TGetProduct,
  TProduct,
} from './types';

interface QuantityProps {
  final_product_id: string;
  product_parts: TProductPart[];
  quantities?: (string | number)[];
}

interface GetFreightOptionsProps {
  final_product_id: string;
  payload: TFreightOptionsPayload;
}

type TFreightOptionsPayload = {
  quantity: number;
  zip_code: number | string;
  product_parts: TProductPart[];
};

type TProductPart = {
  id: string;
  options: {
    format?: string | null;
    page?: string | null;
    color?: string | null;
    stock?: string | null;
    finishing?: string | null;
    refinement?: string | null;
    extra?: string | null;
    auxiliary?: string | null;
  };
};

const productService = ({ baseUrl }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl });

  const getProduct = async ({ slug }: { slug: string }): Promise<TProduct> => {
    const { data }: TGetProduct = await apiBff.get(`/v1/products/${slug}`);
    return data;
  };

  const useProduct = ({ slug }: { slug: string }) =>
    useQuery([`product-${slug}`], () => getProduct({ slug }));

  const getProductQuantity = async ({
    final_product_id,
    product_parts,
    quantities,
  }: QuantityProps) => {
    const { data } = await apiBff.post<TDefaultCombinationsQuantities[]>(
      `/v1/products/${final_product_id}/quantities`,
      { product_parts, quantities },
    );

    return data;
  };

  const getFreightOptions = async ({
    final_product_id,
    payload,
  }: GetFreightOptionsProps) => {
    const { data } = await apiBff.post<TFreightOption[]>(
      `/v1/products/${final_product_id}/shipping-resume`,
      payload,
    );

    return data;
  };

  const useProductQuantity = () => {
    return useMutation(getProductQuantity);
  };

  return {
    getProduct,
    useProduct,
    getProductQuantity,
    useProductQuantity,
    getFreightOptions,
  };
};

export default productService;
