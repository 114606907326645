/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import React from 'react';
import {
  FsGalleryProduct,
  FsImage,
  FsListContent,
  FsTextContent,
  ListContentOrientation,
  ListContentType,
  ListContentVariant,
} from '@printi/ds-offset-react-core';
import Image from 'next/image';
import { reviewProps } from './TrustvoxReviews';
import {
  StyledGalleryProduct,
  StyledReviewGallery,
} from './ReviewGallery.styles';

type ReviewGalleryProps = {
  item: Partial<reviewProps>;
};

export const ReviewGallery = (item: ReviewGalleryProps) => {
  const review = item.item;
  const photosLength = review.review_photos?.length ?? 0;

  if (Object.values(review).length < 1) {
    return;
  }

  const galleryImages = (): any[] => {
    if (!review?.review_photos) {
      return [];
    }

    const images = review?.review_photos?.map((image, index: number) => {
      return {
        index: index + 1,
        full: (
          <Image
            src={image?.image || ''}
            alt={review.opinion_title || ''}
            fill
          />
        ),
        thumb: (
          <Image
            src={
              image?.thumbnail ||
              'https://d1br4h274rc9sc.cloudfront.net/content/paper_b2591f4c2b.png'
            }
            alt={review.opinion_title || ''}
            fill
          />
        ),
      } as any;
    });

    return images;
  };

  return (
    <StyledReviewGallery>
      <StyledGalleryProduct>
        {review?.review_photos?.length === 1 && (
          <FsImage
            className="solo-photo"
            aspectRatio={'1:1'}
            contentSlot={
              <Image
                src={review?.review_photos[0].image}
                alt={`${review.opinion_title || ''} - foto ${review?.review_photos[0].id}`}
                fill
              />
            }
          />
        )}
        {photosLength > 1 && (
          <FsGalleryProduct
            images={galleryImages() as unknown as any[]}
            fullImageHeight={450}
          ></FsGalleryProduct>
        )}
      </StyledGalleryProduct>
      <div className="modal-content">
        <FsTextContent
          heading={''}
          paragraph={''}
          showRateStatus
          rating={review?.rate}
        />
        <FsListContent
          type={ListContentType.Single}
          variant={ListContentVariant.Line}
          orientation={ListContentOrientation.Horizontal}
          showButton={false}
          showTag={false}
          showHeading={true}
          heading={review?.opinion_title || ''}
          description=""
          showLabelLeft={true}
          labelLeft={review?.opinion || ''}
          showContentLeft={true}
          showContentRight={true}
          labelRight={review?.created_at}
          lastItem={true}
        />
        {review?.comments && review.comments.length > 0 && (
          <FsListContent
            type={ListContentType.Single}
            variant={ListContentVariant.Line}
            orientation={ListContentOrientation.Horizontal}
            className="printi-answer"
            showButton={false}
            showTag={false}
            showHeading={true}
            heading={'Resposta da Printi'}
            description=""
            showLabelLeft={true}
            labelLeft={review?.comments[0]?.text || ''}
            showContentLeft={true}
            showContentRight={false}
            lastItem={true}
          />
        )}
      </div>
    </StyledReviewGallery>
  );
};
