'use client';

import {
  FsDescription,
  FsHeading,
  FsRadioButton,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { ChangeEventHandler, forwardRef, useMemo } from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
import Image from 'next/image';
import * as S from './CardSelect.styles';

interface CardSelectProps {
  image?: string;
  heading?: string;
  description?: string;
  selected?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: () => void;
  value?: string;
}
// eslint-disable-next-line
export const CardSelect = forwardRef<HTMLDivElement, CardSelectProps>(
  (
    {
      image,
      heading = 'Heading',
      description,
      selected,
      onChange,
      onClick,
      value,
    },
    ref,
  ) => {
    const { isMobile } = useLayout();

    const Img = useMemo(() => {
      return (
        <S.Image
          contentSlot={
            <Image
              src={
                image ||
                'https://d1br4h274rc9sc.cloudfront.net/content/paper_b2591f4c2b.png'
              }
              alt="Placeholder"
              width={760}
              height={428}
            />
          }
        />
      );
    }, [image]);

    return (
      <S.Container
        selected={selected}
        className="card-option"
        onClick={onClick}
        ref={ref}
      >
        {Img}
        <S.Content>
          <S.HeadingDescription>
            <FsHeading
              size={isMobile ? HeadingSize['2XS'] : HeadingSize.XS}
              tag={HeadingTag.SPAN}
            >
              {heading}
            </FsHeading>
            {description && <FsDescription>{description}</FsDescription>}
          </S.HeadingDescription>
          <FsRadioButton
            selected={selected}
            value={value}
            inputProps={{
              onChange: onChange,
            }}
          />
        </S.Content>
      </S.Container>
    );
  },
);
