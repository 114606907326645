import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { api } from '../../../config/axios/serviceApi';
import { TAxiosDefaultConfig } from '../../aecomStrapiCms/defaults/types';
import { TDenyRule, TGetDenyRules } from './types';

const productRules = ({ baseUrl }: TAxiosDefaultConfig) => {
  const apiCdn: AxiosInstance = api({ baseUrl });

  const getProductDenyRules = async ({
    productId,
  }: {
    productId: string | undefined;
  }): Promise<TDenyRule[]> => {
    if (!productId) {
      return [];
    }

    const { data }: TGetDenyRules = await apiCdn.get(
      `/product/deny-rules?productId=${productId}`,
    );

    return data;
  };

  const useProductDenyRules = ({
    productId,
  }: {
    productId: string | undefined;
  }) => {
    return useQuery({
      queryKey: [`${productId}-deny-rules`],
      queryFn: () => getProductDenyRules({ productId }),
      enabled: !!productId,
    });
  };

  return {
    getProductDenyRules,
    useProductDenyRules,
  };
};

export default productRules;
