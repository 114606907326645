import styled from 'styled-components';

export const Rate = styled.div`
  max-width: 357px;
  gap: 8px;
  display: grid;
  margin: var(--spacing-6x) 0;

  > div:not(.ts-v-footer-container) {
    grid-template-columns: 10px 1fr;
    display: grid;
    align-items: center;
    gap: 8px;
    font-family: var(--font-family-base, Inter);
    font-size: var(--font-size-2xs, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: 18.48px;
    color: var(--color-grey-900);

    > div[role='progressbar'] {
      width: 340px;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 425px;
    width: 425px;
  }

  @media screen and (max-width: 768px) {
    max-width: unset;
    width: 100%;
  }
`;
